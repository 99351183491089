import request from '../utils/request'
const url = process.env.VUE_APP_BASEURL

// 商户公示信息
export const getMerchantInfo = (data) => {
	return request({
		url:`${url}/h5/merchant/info`,
		method:"post",
		data
	})
}