import axios from 'axios';
import { Notify } from 'vant';

//1. 创建axios对象
const service = axios.create();

//2. 请求拦截器
service.interceptors.request.use(config => {
  return config;
}, error => {
  Promise.reject(error);
});

//3. 响应拦截器
service.interceptors.response.use(response => {
  //判断code码
  // if(response.data.code === 200){
  //   Notify({ type: 'success', message: response.data.msg });
  //   return response.data.data;
  // }else{
  //   Notify({ type: 'warning', message: response.data.msg });
  // }
  return response.data
},error => {
  return Promise.reject(error);
});

export default service;
