import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vant from "vant";
import "vant/lib/index.css";

createApp(App).use(store).use(router).use(vant).mount("#app");

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = String(to.meta.title);
  }
  next()
})
