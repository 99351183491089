import request from '../utils/request'
const url = process.env.VUE_APP_BASEURL

// 新增供应商
export const addSupplier = (data) => {
	return request({
		url:`${url}/h5/supplier/add`,
		method:"post",
		data
	})
}
// 省市区街道
export const getListByCode = (data) => {
	return request({
		url:`${url}/h5/region/getListByCode`,
		method:"post",
		data
	})
}
// 市场列表
export const getMarket = (data) => {
	return request({
		url:`${url}/h5/market`,
		method:"post",
		data
	})
}
// 市场档口列表
export const getBooth = (data) => {
	return request({
		url:`${url}/h5/market/booth`,
		method:"post",
		data
	})
}
// 根据town获取省市区镇信息
export const getRegionTown = (data) => {
	return request({
		url:`${url}/h5/region/town`,
		method:"post",
		data
	})
}
// 获取经营类别列表
export const getBusinessCategory = () => {
	return request({
		url:`${url}/h5/merchant/business_category`,
		method:"post"
	})
}
// 获取企业类型列表
export const getCompanyType = () => {
	return request({
		url:`${url}/h5/merchant/company_type`,
		method:"post"
	})
}
// 上传文件
export const fileUpload = (data) => {
	return request({
		url:`${url}/h5/file/upload`,
		method:"post",
		data
	})
}
// 新增零售商、批发商
export const addMerchant = (data) => {
	return request({
		url:`${url}/h5/merchant/add`,
		method:"post",
		data
	})
}